.scrollToTopWrapper {
    display: none;
    cursor: pointer;
    .scrollToTop {
        position: fixed;
        bottom: 50px;
        right: 50px;
        width: 44px;
        height: 44px;
        z-index: 100;
        border-radius: 50%;
        border: none;
        background-color: #12337588;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        svg {
            fill: whitesmoke;
            width: 20px;
            height: 20px;
        }
    }
}
